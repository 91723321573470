import React from 'react'
import Nav from 'react-bootstrap/Nav'

const Menu =(props)=>{
  return(
    <Nav

  onSelect={(selectedKey) =>{ props.onChange(selectedKey)}}
  >
    <Nav.Item>
      <Nav.Link eventKey="crear">Crear</Nav.Link>
    </Nav.Item>
    <Nav.Item>
      <Nav.Link eventKey="editar">Editar</Nav.Link>
    </Nav.Item>
  </Nav>
  )
}
export default Menu
