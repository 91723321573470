import React, { Component } from 'react';
import firebase from 'firebase'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import NavDropdown from 'react-bootstrap/NavDropdown'
import ProgressBar from 'react-bootstrap/ProgressBar'
import Helper from './lib/Helper'

var fecha;
var archivo=[];
class Media extends Component {
constructor(props){
  super(props)
  this.state={
    uid:'8B57J61q4bM51u9oF7tKln340Fm2',
   uploadValue:'',
   description:'',
   subir:'',
   inicio:'',
   fin:'',
   picture:'',
   home:'',
   banner:[],
   bannerhome:[],
   bannergallery:[],
   modalbanner:false,
   modalhome:false,
   gallerymodal:false

  }
}
componentWillMount(){

  const extr= firebase.database().ref().child("blog/image")
		 extr.on('value', (snapshot) => {

		 			snapshot.forEach((data) => {
		 				this.state.banner.push({
		 						Url:data.val().Url,
                child:data.key
		 				});

		 })
		 });
}

updateAll(){
  this.setState({
  banner:[],
  bannerhome:[],
  bannergallery:[],})
  const extr= firebase.database().ref().child("blog/image")
		 extr.on('value', (snapshot) => {

		 			snapshot.forEach((data) => {
		 				this.state.banner.push({
		 						Url:data.val().Url,
                child:data.key
		 				});


		 })
		 });
     const extrgallery= firebase.database().ref().child("blog/image")
   		 extrgallery.on('value', (snapshot) => {

   		 			snapshot.forEach((data) => {
   		 				this.state.bannergallery.push({
   		 						Url:data.val().Url,
                   child:data.key
   		 				});

   		 })
   		 });
}
  handleOnChange(event){
     archivo=event.target.files[0]
    this.setState({
      archivo:event.target.files[0]/*guarda el archivo en un estado para despues subirlo cuando se le de click*/
    })
  }
  handleClose() {
  this.setState({ modalhome: false,modalbanner:false,gallerymodal:false });
  }
  modalhome(){
    this.setState({ modalhome: true})
  }
  modalbanner(){
    this.setState({ modalbanner: true})
  }
  gallerymodal(){
    this.setState({ gallerymodal: true})
  }
  picture(event){
     archivo=event.target.files[0]
    this.setState({
      picture:event.target.files[0]/*guarda el archivo en un estado para despues subirlo cuando se le de click*/
    })
  }
  subir(){
    fecha = new Date();
     var segundos = fecha.getSeconds();
     var minutos = fecha.getMinutes();
     var horas = fecha.getHours();
     var anio = fecha.getFullYear();
     var mes = fecha.getMonth();
     var dia = fecha.getDate();
     var fet=anio+'a'+mes+'a'+dia+'a'+horas+'a'+minutos+'a'+segundos
    this.setState({subir:'subiendo'})
    const file = this.state.archivo
    const storageRef = firebase.storage().ref("Gallery/Banners/"+fet+".jpg")
    const task = storageRef.put(file)

    task.on('state_changed', (snapshot) => {
      let percentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
      this.setState({
        uploadValue: percentage
      })
    }, (error) => {
      alert("hubo un error, vuelve a intentarlo.")
      this.setState({
        uploadValue:'',
        subir:''

      })
    }, () =>{
      task.snapshot.ref.getDownloadURL().then((downloadURL)=> {



        const gallery={
          Url:downloadURL
        }
        Helper.AddBanner(this.state.uid,fet,(gallery)).then(()=>{/*informacion de archivo*/
          alert("el archivo se ha subido correctamente")

        }).catch(function(error){
        let MS=error.message
        alert(MS)
        });
        this.setState({
          uploadValue:'',
          subir:'',
          banner:[]
        })

  })
        });
  }


  UploadPicture(){
    fecha = new Date();
     var segundos = fecha.getSeconds();
     var minutos = fecha.getMinutes();
     var horas = fecha.getHours();
     var anio = fecha.getFullYear();
     var mes = fecha.getMonth();
     var dia = fecha.getDate();
     var fet=anio+'a'+mes+'a'+dia+'a'+horas+'a'+minutos+'a'+segundos

    this.setState({subir:'subiendo'})
    const file = this.state.picture
    const storageRef = firebase.storage().ref("Gallery/Pictures/"+fet+".jpg")
    const task = storageRef.put(file)

    task.on('state_changed', (snapshot) => {
      let percentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
      this.setState({
        uploadValue: percentage
      })
    }, (error) => {
      alert("hubo un error, vuelve a intentarlo.")
      this.setState({
        uploadValue:'',
        subir:''

      })
    }, () =>{
      task.snapshot.ref.getDownloadURL().then((downloadURL)=> {


        const gallery={
          Url:downloadURL
        }
        Helper.AddPicture(this.state.uid,fet,(gallery)).then(()=>{/*informacion de archivo*/
          alert("el archivo se ha subido correctamente")

          this.componentWillMount()
        }).catch(function(error){
        let MS=error.message
        alert(MS)
        });
        this.setState({
          uploadValue:'',
          subir:''
        })
  })
        });
  }




  home(){
    fecha = new Date();
     var segundos = fecha.getSeconds();
     var minutos = fecha.getMinutes();
     var horas = fecha.getHours();
     var anio = fecha.getFullYear();
     var mes = fecha.getMonth();
     var dia = fecha.getDate();
     var fet=anio+'a'+mes+'a'+dia+'a'+horas+'a'+minutos+'a'+segundos
    this.setState({subir:'subiendo'})
    const file = this.state.home
    const storageRef = firebase.storage().ref("Gallery/Home/"+fet+".jpg")
    const task = storageRef.put(file)

    task.on('state_changed', (snapshot) => {
      let percentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
      this.setState({
        uploadValue: percentage
      })
    }, (error) => {
      alert("hubo un error, vuelve a intentarlo.")
      this.setState({
        uploadValue:'',
        subir:''

      })
    }, () =>{
      task.snapshot.ref.getDownloadURL().then((downloadURL)=> {


        const gallery={
          Url:downloadURL
        }
        Helper.AddPictureHome(this.state.uid,fet,(gallery)).then(()=>{/*informacion de archivo*/
          alert("el archivo se ha subido correctamente")

          {this.updateAll()}
        }).catch(function(error){
        let MS=error.message
        alert(MS)
        });
        this.setState({
          uploadValue:'',
          subir:''
        })
  })
        });
  }
  DeleteBannerPicture(e){
//alert(JSON.stringify(e[1]))
this.setState({
  banner:[],
})
    let userNamePath = "Gallery/Banners/"+e[1]
  firebase.database().ref(userNamePath).remove()

  var desertRef = firebase.storage().ref('Gallery/Banners/'+e[1]+'.jpg');
this.updateAll()
// Delete the file
desertRef.delete().then(function() {
  // File deleted successfully
  alert('listo')
  {this.updateAll()}
}).catch(function(error) {
  // Uh-oh, an error occurred!
});

  }
  DeleteBannergalleryPicture(e){
//alert(JSON.stringify(e[1]))
this.setState({
  bannergallery:[],
})
    let userNamePath = "Gallery/Pictures/"+e[1]
  firebase.database().ref(userNamePath).remove()

  var desertRef = firebase.storage().ref('Gallery/Pictures/'+e[1]+'.jpg');

// Delete the file
desertRef.delete().then(function() {
  // File deleted successfully
  alert('listo')
  {this.updateAll()}
}).catch(function(error) {
  // Uh-oh, an error occurred!
});

  }
  updatebanner(){

    const todos = [];
    const extr= firebase.database().ref().child("Gallery/Banners")
  		 extr.on('value', (snapshot) => {

  		 			snapshot.forEach((data) => {
  		 				todos.push({
  		 						Url:data.val().Url,
                  child:data.key
  		 				});

  		 this.setState({
  		 banner:todos
  		 })
  		 })
  		 });
  }
banner(){
  return(
    <div>
    <p>Banner</p>
    <input className="CargarArchivoBoton" type="file" onChange={this.handleOnChange.bind(this)} label="Cargar Archivo" s={12} />
    {this.state.archivo?<Button label="subir" onClick={this.subir.bind(this)} className="botonCA">Subir</Button>:null}
<br/>
    {this.state.banner.map(pic => (
      <div>
      <br/>
        <img
          className="Logo_Img_Sm"
          src={pic.Url}
        />
        <Button label="subir" onClick={()=>this.props.onChange([pic.Url,pic.child])} className="botonCA">Seleccionar</Button>{'  '}
{'  '}
        <Button label="subir"
          onClick={() => {navigator.clipboard.writeText(pic.Url)}}className="botonCA">copiar url</Button>
        </div>
        ))}
        {this.state.subir?
          <div>
          <label>Subiendo.....</label>
          <br/>
        <ProgressBar>
        <ProgressBar striped variant="success" now={this.state.uploadValue} key={1} />
        </ProgressBar></div>:null}
    </div>
  )
}
gallery(){
  const bannergallery=this.state.bannergallery
  return(
    <div>
    <p>Picture</p>
    <input className="CargarArchivoBoton" type="file" onChange={this.picture.bind(this)} label="Cargar Archivo" s={12} />{'  '}
    {this.state.picture?<Button label="subir" onClick={this.UploadPicture.bind(this)} className="botonCA">Subir</Button>:null}
    {this.state.subir?
      <div>
      <label>Subiendo.....</label>
      <br/>
    <ProgressBar>
    <ProgressBar striped variant="success" now={this.state.uploadValue} key={1} />
    </ProgressBar></div>:null}

    {bannergallery.map(pic => (
      <div>
      <br/>
        <img
          className="Logo_Img_Sm"
          src={pic.Url}
        />
        <Button label="subir" onClick={()=>this.props.onChange([pic.Url,pic.child])} className="botonCA">Seleccionar</Button>


        </div>
        ))}

    </div>
  )
}

  render() {

    return (
    <div>


<Button variant="outline-info" onClick={this.modalbanner.bind(this)}>ver imagenes</Button>

<Modal show={this.state.modalbanner} onHide={this.handleClose.bind(this)}>
  <Modal.Header closeButton>
    <Modal.Title>Banner</Modal.Title>
  </Modal.Header>
  <Modal.Body>
  <div>
{this.banner()}
  </div>
  </Modal.Body>
  <Modal.Footer>

    <Button variant="secondary" onClick={this.handleClose.bind(this)}>
      Close
    </Button>
  </Modal.Footer>
</Modal>
{/*<Modal show={this.state.gallerymodal} onHide={this.handleClose.bind(this)}>
  <Modal.Header closeButton>
    <Modal.Title>Galería</Modal.Title>
  </Modal.Header>
  <Modal.Body>
  <div>
{this.gallery()}
  </div>
  </Modal.Body>
  <Modal.Footer>

    <Button variant="secondary" onClick={this.handleClose.bind(this)}>
      Close
    </Button>
  </Modal.Footer>
</Modal>*/}









        </div>
    );
  }
}

export default Media;
