// tslint:disable:max-line-length
import { EditableType } from '@react-page/core';

const contents: EditableType[] = [
  {
    id: '1',
    cells: [
      {
        id: '5ac89ec4-7536-4120-a072-8eedad0a48ff',
        inline: null,
        size: 12,
        rows: [
          {
            id: 'd813d094-215a-43ec-bcdd-d5fd6258b915',
            cells: [
              {
                id: '3fde7b92-fbfe-4b2d-85b3-bad431939df6',
                inline: null,
                size: 12,
                content: {
                  plugin: {
                    name: 'ory/editor/core/content/slate',
                    version: '0.0.4',
                  },
                  state: {
                    slate: [
                      {
                        data: {
                          align: 'center',
                        },
                        type: 'HEADINGS/HEADING-ONE',
                        children: [
                          {
                            text: 'ReactPage',
                          },
                        ],
                      },
                      {
                        data: {
                          align: 'left',
                        },
                        type: 'PARAGRAPH/PARAGRAPH',
                        children: [
                          {
                            text: '',
                          },
                          {
                            data: {
                              href: 'https://www.ory.sh/',
                            },
                            type: 'LINK/LINK',
                            children: [
                              {
                                text: 'ORY',
                              },
                            ],
                          },
                          {
                            text:
                              ' is a company building and maintaining developer tools for a safer, more accessible web. You might also like our other ',
                          },
                          {
                            data: {
                              href: 'https://github.com/ory',
                            },
                            type: 'LINK/LINK',
                            children: [
                              {
                                text: 'Open Source',
                              },
                            ],
                          },
                          {
                            text:
                              ' tools! ReactPage is a smart, extensible and modern editor ("WYSIWYG") for the web written in React.',
                          },
                        ],
                      },
                      {
                        data: {
                          align: 'left',
                        },
                        type: 'PARAGRAPH/PARAGRAPH',
                        children: [
                          {
                            text:
                              'ReactPage was written because we urgently needed a robust and modern content editing solution for our open education platform ',
                          },
                          {
                            data: {
                              href: 'https://en.serlo.org/serlo',
                            },
                            type: 'LINK/LINK',
                            children: [
                              {
                                text: 'serlo.org',
                              },
                            ],
                          },
                          {
                            text:
                              '. Serlo is the largest open education platform in Germany, works like the Wikipedia and is used by millions every year.',
                          },
                        ],
                      },
                    ],
                  },
                },
              },
            ],
          },
          {
            id: 'c15959de-4767-41b7-9814-71626d6cbcdb',
            cells: [
              {
                id: '33c58cda-94b5-4c2e-9232-9aa27a4aff8d',
                inline: null,
                size: 6,
                content: {
                  plugin: {
                    name: 'ory/editor/core/content/slate',
                    version: '0.0.4',
                  },
                  state: {
                    slate: [
                      {
                        data: {
                          align: 'left',
                        },
                        type: 'HEADINGS/HEADING-THREE',
                        children: [
                          {
                            text: 'ORY Sites',
                          },
                        ],
                      },
                      {
                        data: {},
                        type: 'PARAGRAPH/PARAGRAPH',
                        children: [
                          {
                            text: 'ORY Sites is an ',
                          },
                          {
                            text: 'next-gen',
                            'EMPHASIZE/STRONG': true,
                          },
                          {
                            text: ' ',
                          },
                          {
                            text: 'open source static site generator',
                            'EMPHASIZE/STRONG': true,
                          },
                          {
                            text:
                              ' based on ReactPage. Create stunning websites, write your own designs and plugins, and be done with databases, application servers, and security updates.',
                          },
                        ],
                      },
                      {
                        data: {
                          align: 'center',
                        },
                        type: 'HEADINGS/HEADING-FIVE',
                        children: [
                          {
                            text: '',
                          },
                          {
                            data: {
                              href:
                                'https://www.ory.sh/sites?utm_source=github&utm_medium=link&utm_campaign=editor_demo',
                            },
                            type: 'LINK/LINK',
                            children: [
                              {
                                text: 'Learn more about ORY Sites!',
                              },
                            ],
                          },
                          {
                            text: '',
                          },
                        ],
                      },
                      {
                        data: {},
                        type: 'PARAGRAPH/PARAGRAPH',
                        children: [
                          {
                            text: '',
                          },
                        ],
                      },
                    ],
                  },
                },
              },
              {
                id: 'd5e4efbe-66f0-4344-9df0-f0720b5d1f5d',
                inline: null,
                size: 6,
                content: {
                  plugin: {
                    name: 'ory/sites/plugin/content/html5-video',
                    version: '0.0.1',
                  },
                  state: {
                    url: 'images/app-preview.mp4',
                  },
                },
              },
            ],
          },
          {
            id: '5f33bca6-4ab3-4429-a38f-4d02fb5a16e6',
            cells: [
              {
                id: '640485f1-04d4-4579-81e0-5d8aa1dbfe24',
                inline: null,
                size: 12,
                content: {
                  plugin: {
                    name: 'ory/editor/core/content/slate',
                    version: '0.0.4',
                  },
                  state: {
                    slate: [
                      {
                        data: {
                          align: 'center',
                        },
                        type: 'HEADINGS/HEADING-TWO',
                        children: [
                          {
                            text: "What's the problem?",
                          },
                        ],
                      },
                    ],
                  },
                },
              },
            ],
          },
          {
            id: '09c5e133-ef5c-40ce-965f-a14200e4f162',
            cells: [
              {
                id: '5e813953-98b7-41aa-b077-42923cc0d2c6',
                inline: 'right',
                size: 5,
                content: {
                  plugin: {
                    name: 'ory/editor/core/content/image',
                    version: '0.0.1',
                  },
                  state: {
                    src: '/images/front.png',
                  },
                },
              },
              {
                id: '2fff5d50-f252-478b-b354-181300e21419',
                inline: null,
                size: 12,
                content: {
                  plugin: {
                    name: 'ory/editor/core/content/slate',
                    version: '0.0.4',
                  },
                  state: {
                    slate: [
                      {
                        data: {},
                        type: 'PARAGRAPH/PARAGRAPH',
                        children: [
                          {
                            text:
                              'We had to realize that existing open source content editing solutions had one of the three flaws:\r',
                          },
                        ],
                      },
                      {
                        data: {},
                        type: 'LISTS/UNORDERED-LIST',
                        children: [
                          {
                            data: {},
                            type: 'LISTS/LIST-ITEM',
                            children: [
                              {
                                text:
                                  'The produced markup was horrific, a lot of sanitation had to take place and XSS is always a threat.\r',
                              },
                            ],
                          },
                          {
                            data: {},
                            type: 'LISTS/LIST-ITEM',
                            children: [
                              {
                                text:
                                  'The author must learn special mark up, like markdown, before being able to produce content. These text-based solutions are usually unable to specify a layout and complex data structures like tables are annoying to edit.\r',
                              },
                            ],
                          },
                          {
                            data: {},
                            type: 'LISTS/LIST-ITEM',
                            children: [
                              {
                                text:
                                  'Promising libraries potentially solving the above where abandoned by their maintainers, because it started as a special use case, or a free-time project.\r',
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                },
              },
            ],
          },
          {
            id: 'da7ba725-44a0-4be5-9e5d-53db76950b15',
            cells: [
              {
                id: 'ede254d9-f54f-47c1-85c3-e0e09e816264',
                inline: null,
                size: 12,
                content: {
                  plugin: {
                    name: 'ory/editor/core/content/slate',
                    version: '0.0.4',
                  },
                  state: {
                    slate: [
                      {
                        data: {
                          align: 'center',
                        },
                        type: 'HEADINGS/HEADING-TWO',
                        children: [
                          {
                            text: "So what's different?",
                          },
                        ],
                      },
                    ],
                  },
                },
              },
            ],
          },
          {
            id: 'de26482c-ba8a-42b3-bdfc-b4f0761e2dad',
            cells: [
              {
                id: 'b2332df1-94c4-4136-867f-14c93e1efe48',
                inline: null,
                size: 4,
                content: {
                  plugin: {
                    name: 'ory/editor/core/content/video',
                    version: '0.0.1',
                  },
                  state: {
                    src: 'https://www.youtube.com/watch?v=dkc65_l0Kfc',
                  },
                },
              },
              {
                id: '71d9437b-38ef-40f9-be8b-0a5517734922',
                inline: null,
                size: 8,
                content: {
                  plugin: {
                    name: 'ory/editor/core/content/slate',
                    version: '0.0.4',
                  },
                },
              },
            ],
          },
          {
            id: 'b3c394b2-f99c-4286-a348-0dc5c4592f05',
            cells: [
              {
                id: 'af4d6d7b-57ac-43f2-aff6-6b141dfcf98e',
                inline: null,
                size: 12,
                content: {
                  plugin: {
                    name: 'ory/editor/core/content/spacer',
                    version: '0.0.1',
                  },
                  state: {
                    height: 48,
                  },
                },
              },
            ],
          },
          {
            id: '338463af-5dfb-4e53-a53d-a47855247269',
            cells: [
              {
                id: 'f9a5c347-8e5f-464f-8af9-b130ec2dd3bb',
                inline: null,
                size: 12,
                content: {
                  plugin: {
                    name: 'ory/editor/core/content/slate',
                    version: '0.0.4',
                  },
                  state: {
                    slate: [
                      {
                        data: {
                          align: 'center',
                        },
                        type: 'HEADINGS/HEADING-TWO',
                        children: [
                          {
                            text: 'Feature Spotlight',
                          },
                        ],
                      },
                    ],
                  },
                },
              },
            ],
          },
          {
            id: '111f3e10-e62f-483c-8937-9b95ca05c48d',
            cells: [
              {
                id: '9e726399-4a2c-4ea7-b0f3-7742d430a861',
                inline: null,
                size: 12,
                content: {
                  plugin: {
                    name: 'ory/editor/core/content/divider',
                    version: '0.0.1',
                  },
                  state: {},
                },
              },
            ],
          },
          {
            id: '76814458-ee0c-49c4-b011-937ce7e883fc',
            cells: [
              {
                id: '4825fda3-916f-44f9-ac02-3be553bcd871',
                inline: null,
                size: 8,
                content: {
                  plugin: {
                    name: 'ory/editor/core/content/slate',
                    version: '0.0.4',
                  },
                  state: {
                    slate: [
                      {
                        data: {},
                        type: 'HEADINGS/HEADING-THREE',
                        children: [
                          {
                            text: 'Rich Inline Editing',
                          },
                        ],
                      },
                      {
                        data: {},
                        type: 'PARAGRAPH/PARAGRAPH',
                        children: [
                          {
                            text:
                              'Presentation and modification happen in the same canvas, at the same time. Now you always know what your content looks like on desktop, tablet and mobile, in real-time!',
                          },
                        ],
                      },
                    ],
                  },
                },
              },
              {
                id: '76d7587b-d978-4958-b54f-91ccb1e3d0a2',
                inline: null,
                size: 4,
                content: {
                  plugin: {
                    name: 'ory/editor/core/content/image',
                    version: '0.0.1',
                  },
                  state: {
                    src: '/images/create-content.png',
                  },
                },
              },
            ],
          },
          {
            id: '2e1d1ba8-cdbe-4175-9990-26cdc15fce5c',
            cells: [
              {
                id: '412e3261-1fb8-474f-965e-39cbc5a16d18',
                inline: null,
                size: 4,
                content: {
                  plugin: {
                    name: 'ory/editor/core/content/image',
                    version: '0.0.1',
                  },
                  state: {
                    src: '/images/responsive.png',
                  },
                },
              },
              {
                id: '02125c61-3120-49ef-9b50-70ae79409bea',
                inline: null,
                size: 8,
                content: {
                  plugin: {
                    name: 'ory/editor/core/content/slate',
                    version: '0.0.4',
                  },
                  state: {
                    slate: [
                      {
                        data: {},
                        type: 'HEADINGS/HEADING-THREE',
                        children: [
                          {
                            text: 'Responsive Layouts',
                          },
                        ],
                      },
                      {
                        data: {},
                        type: 'PARAGRAPH/PARAGRAPH',
                        children: [
                          {
                            text:
                              "Today's content is more than a monolithic blob of text. ReactPage allows you to define your own layout blocks, and create content that looks good on all devices.",
                          },
                        ],
                      },
                    ],
                  },
                },
              },
            ],
          },
          {
            id: 'ec4474f6-a418-4628-831b-03a7e64fd500',
            cells: [
              {
                id: '78269a8f-7b29-4703-b107-b399eac3e365',
                inline: null,
                size: 8,
                content: {
                  plugin: {
                    name: 'ory/editor/core/content/slate',
                    version: '0.0.4',
                  },
                  state: {
                    slate: [
                      {
                        data: {},
                        type: 'HEADINGS/HEADING-THREE',
                        children: [
                          {
                            text: 'Sane mark-up',
                          },
                        ],
                      },
                      {
                        data: {},
                        type: 'PARAGRAPH/PARAGRAPH',
                        children: [
                          {
                            text:
                              "Most web editors work on top of the DOM. This is dangerous (XSS), hard to maintain and difficult to parse. ReactPage uses JSON only - everywhere! It's sane, tested, clean and minimal.",
                          },
                        ],
                      },
                    ],
                  },
                },
              },
              {
                id: 'e8c5696c-4769-483c-a962-2fd5f4727c0c',
                inline: null,
                size: 4,
                content: {
                  plugin: {
                    name: 'ory/editor/core/content/image',
                    version: '0.0.1',
                  },
                  state: {
                    src: '/images/sane-markup.png',
                  },
                },
              },
            ],
          },
          {
            id: '6f226a6b-e4e8-405a-a0f8-6f370dc05763',
            cells: [
              {
                id: '6c11a0d6-6857-4917-9692-eae056fd9500',
                inline: null,
                size: 12,
                layout: {
                  plugin: {
                    name: 'ory/editor/core/layout/background',
                    version: '0.0.1',
                  },
                  state: {
                    background: '/images/sea-bg.jpg',
                  },
                },
                rows: [
                  {
                    id: 'c9d1ff2a-f7d1-410c-b275-9dd6200114c5',
                    cells: [
                      {
                        id: 'fa87ac49-73b9-4202-9034-f752fa83273c',
                        inline: null,
                        size: 4,
                        content: {
                          plugin: {
                            name: 'ory/editor/core/content/image',
                            version: '0.0.1',
                          },
                          state: {
                            src: '/images/react.png',
                          },
                        },
                      },
                      {
                        id: '491d3370-e551-4b05-be4f-c926d52ee08b',
                        inline: null,
                        size: 8,
                        content: {
                          plugin: {
                            name: 'ory/editor/core/content/slate',
                            version: '0.0.4',
                          },
                        },
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
];
export default contents;
