import React,{useState,useEffect} from 'react'
import web from '../Web'
import Table from 'react-bootstrap/Table'
import Edition from '../edition/'
import Modal from 'react-bootstrap/Modal'

import Button from 'react-bootstrap/Button'
const ShowArticles=()=>{
  const [posts, setPosts]=useState()
  const [isEditing, setEditing]=useState(false)
  const [dataToEdit, setDataToEdit]=useState()

  function close(){
    setEditing(false)
  }
  function open(e){
    setDataToEdit(e)
    setEditing(true)
  }
  useEffect(()=>{
    var url = web+'Api/Get_VideosStudents.php';
    const student={
      id:'1'
    }

    fetch(url, {
      method: 'POST', // or 'PUT'
      body: JSON.stringify(student), // data can be `string` or {object}!
      header: {'Content-Type': 'application/json'}
  }).then(res => res.json())
    .catch(error => console.error('Error:', error))
    .then(response => {
        setPosts(response.reverse())
      })


  },isEditing)


  return(
    <div>
    {isEditing?
      <Modal

    size="lg"
    aria-labelledby="contained-modal-title-vcenter"
    centered
      show={isEditing}>
        <Modal.Body>
      <Edition data={dataToEdit}/>
      </Modal.Body>
      <Modal.Footer>

        <Button variant="secondary" onClick={()=>close()}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
      :
    <div>
    {posts?
    <div>
    <Table striped bordered hover>
<thead>
<tr>
<th>#</th>
<th>thumbnail</th>
<th>title</th>
</tr>
</thead>
<tbody>
    {posts.map((dato, key) => {
      return(

<tr>
  <td onClick={()=>open(dato)}>{dato.Id_video_conversation}</td>
  <td onClick={()=>open(dato)}><img src={dato.image_url_thumbnail} className="Logo_Img_Sm"/></td>
  <td onClick={()=>open(dato)}>{dato.title}</td>
</tr>


      )
    })}

    </tbody>
    </Table>
    </div>:null}
    </div>}
    </div>
  )
}
export default ShowArticles;
