import React from 'react';
import ReactDOM from 'react-dom';

import {Redirect, Route, Switch } from "react-router";

import Nav from 'react-bootstrap/Nav'
import * as KeepStateEditor from './create'
import Menu from './menu'
/*
import '@fortawesome/fontawesome-free/css/all.min.css'; import
'bootstrap-css-only/css/bootstrap.min.css'; import
'mdbreact/dist/css/mdb.css';*/

const Router=()=>{
  return (
  <Switch>

        <Route path="/" component={KeepStateEditor} exact/>

  </Switch>
)
}


export default Router;
//ReactDOM.render(document.getElementById('root'))


//ReactDOM.render(
  //  <Provider store={store}>
  //      <App />
  //  </Provider>,
  //  document.getElementById('root'));

///ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
