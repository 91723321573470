import '@react-page/core/lib/index.css'; // we also want to load the stylesheets
import Editor from '@react-page/editor';
import '@react-page/ui/lib/index.css';
import React from 'react';
import ReactDOM from 'react-dom';
// The content state
import contents from './contents';
import customLayoutPluginWithInitialState from './customLayoutPluginWithInitialState';
import { plugins } from './plugins';
import './styles.css';
import firebase from 'firebase'
import ShowImages from './showImages'
import web from './Web'
import Menu from './menu'
import ShowArticles from './showArticles/'
import {
  Button,
  FormGroup,
  FormControlLabel,
  Switch,
  Select,
  Input
} from '@material-ui/core';
import { defaultSlate } from './slate';
import { EditorProps } from '@react-page/editor';


var firebaseConfig = {
   apiKey: "AIzaSyA6hNPGzMtedqVdmdsX5NgXsIk27b3yU5g",
   authDomain: "conversation-hackers.firebaseapp.com",
   databaseURL: "https://conversation-hackers.firebaseio.com",
   projectId: "conversation-hackers",
   storageBucket: "conversation-hackers.appspot.com",
   messagingSenderId: "702093406884",
   appId: "1:702093406884:web:931b16010717f64d"
 };

 firebase.initializeApp(firebaseConfig);

if (
  process.env.NODE_ENV !== 'production' &&
  process.env.REACT_APP_TRACE_UPDATES
) {
  const { whyDidYouUpdate } = require('why-did-you-update');
  whyDidYouUpdate(React);
}
const LANGUAGES = [
  {
    lang: 'en',
    label: 'English',
  },
  {
    lang: 'de',
    label: 'Deutsch',
  },
];
export const KeepStateEditor: React.FC<EditorProps> = ({ value, ...props }) => {
  const [state, setState] = React.useState(value);
  const [title, setTitle] = React.useState('');
  const [image, setImage] = React.useState('');
  const [home, setHome]= React.useState(true);
  const [edit, setEdit]= React.useState(false);


  // here you would normally persist the state somewhere (e.g a database)
  // <Editor /> is stateful, so you don't nesseary have to keep the value updated
  // if you do, you have to guarantee that the value is referencially equal to what has been passed by `onChange`
  // or the editor will blur its fields (and other problems)

  const saveBlog=()=>{
    //alert(JSON.stringify(state))
    const data={
      info:JSON.stringify(state),
      title:title,
      image:image
    }
if (title&&image) {
    var url = web+'blog/Create_post.php';
    fetch(url, {
      method: 'POST', // or 'PUT'
      body: JSON.stringify(data), // data can be `string` or {object}!
      headers: {'Content-Type': 'application/json'}
    })
    .catch(error => {alert(JSON.stringify(error))})
    .then(response => {alert('listo')} );
  }else{
    alert('llena todos los campos')
  }
  }
  const [allowMove, setAllowMove] = React.useState(true);
  const onAllowMoveChanged = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>, checked: boolean) =>
      setAllowMove(checked),
    []
  );
  const [allowResize, setAllowResize] = React.useState(true);
  const onAllowResizeChanged = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>, checked: boolean) =>
      setAllowResize(checked),
    []
  );

  const [hide, sethide] = React.useState(false);

  const onSethideChanged = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>, checked: boolean) =>
      sethide(checked),
    []
  );

  const [lang, setLang] = React.useState('en');

  const onSetLang = React.useCallback(
    (e: React.ChangeEvent<{ name?: string; value: unknown }>) =>
      setLang(e.target.value as string),
    []
  );
  const onSetTitle = React.useCallback(
    (e: React.ChangeEvent<{ name?: string; value: unknown }>) =>
      setTitle(e.target.value as string),
    []
  );

  const onSetImage = (e)=>{
    setImage(e[0])
  }
  const onSetHome = (e)=>{
    if (e=="crear") {
      setHome(true);
      setEdit(false);
    }

    if (e=="editar") {
      setHome(false);
      setEdit(true);
    }
  }
  return (
    <div>
    <Menu onChange={onSetHome}/>
    {edit?<ShowArticles/>:null}


    {home?
      <div
        style={{
          display: hide ? 'none' : 'block',
        }}
      >
      <Input placeholder={"titulo"} onChange={onSetTitle} />

      <img src={image} className="Logo_Img_Sm"/>
        <ShowImages onChange={onSetImage}/>
        <Editor
          {...props}
          allowMoveInEditMode={allowMove}
          allowResizeInEditMode={allowResize}
          value={state}
          onChange={setState}
          onChangeLang={setLang}
          lang={lang}
          languages={LANGUAGES}
        />
        <Button variant="outlined" onClick={() => setState(value)}>
          Reset this editor
        </Button>
        <Button onClick={saveBlog}> guardar</Button>
      </div>:null}
    </div>
  );
};


// Render the editables - the areas that are editable
const elements = document.querySelectorAll<HTMLDivElement>('.editable');
elements.forEach((element, index) => {
  ReactDOM.render(
    <KeepStateEditor
      plugins={plugins}
      defaultPlugin={
        element.dataset.id === '10'
          ? customLayoutPluginWithInitialState()
          : defaultSlate
      }
      value={contents[index]}
    />,

    element
  );
});

// Render as beautified mark up (html)/
/*
ReactDOM.render(
  <Editor plugins={plugins} value={contents[0]} readOnly={true} />,
  document.getElementById('editable-static')
);*/
