import React,{useEffect, useState} from 'react';
import Editor from '@react-page/editor';
import { plugins } from '../plugins';
import ShowImages from '../showImages'
import {
  Button,
  FormGroup,
  FormControlLabel,
  Switch,
  Select,
  Input
} from '@material-ui/core';
import web from '../Web'

const Edit=(props)=>{
  const [data, setData]=useState()
  const [title, setTitle]=useState(props.data.title);
  const [image, setImage]=useState(props.data.image_url_thumbnail);
  const[id]=useState(props.data.Id_video_conversation)


useEffect(()=>{
  var url = web+'blog/Get_post.php';
  const student={
    id:id
  }

  fetch(url, {
    method: 'POST', // or 'PUT'
    body: JSON.stringify(student), // data can be `string` or {object}!
    header: {'Content-Type': 'application/json'}
}).then(res => res.json())
  .catch(error => console.error('Error:', error))
  .then(response => {
    setData(response)
      //alert(JSON.stringify(response))
    })


},props)

const update=()=>{
  setData('')
  var url = web+'blog/Get_post.php';
  const student={
    id:id
  }

  fetch(url, {
    method: 'POST', // or 'PUT'
    body: JSON.stringify(student), // data can be `string` or {object}!
    header: {'Content-Type': 'application/json'}
}).then(res => res.json())
  .catch(error => console.error('Error:', error))
  .then(response => {
    setData(response)
      //alert(JSON.stringify(response))
    })
}
const onSetTitle=(e)=>{
  setTitle(e.target.value)
}
const onSetImage=(e)=>{
  setImage(e[0])
}
const saveBlog=()=>{
  //alert(JSON.stringify(state))
  const datas={
    id:id,
    info:JSON.stringify(data),
    title:title,
    image:image
  }
if (title&&image) {
  var url = web+'blog/Update_post.php';
  fetch(url, {
    method: 'POST', // or 'PUT'
    body: JSON.stringify(datas), // data can be `string` or {object}!
    headers: {'Content-Type': 'application/json'}
  })
  .catch(error => {alert(JSON.stringify(error))})
  .then(response => {
    alert('listo')

      update()
  }
   );

}else {
  alert('llena todos los campos')
}

}


if (data) {
  return<div>

  <Input placeholder={"titulo"} onChange={onSetTitle} value={title} />
  <img src={image} className="Logo_Img_Sm"/>
    <ShowImages onChange={onSetImage}/>
  <Editor plugins={plugins}
  onChange={setData} value={data} readOnly={false} />
  <Button onClick={saveBlog}>actualizar</Button>
  </div>
}else {
  return<p>cargando...</p>
}


}

export default Edit;
